import { useState } from "react";
import { Avatar, Box, Typography, Paper, Grid } from "@mui/material";
import Sidenav from "../components/NavBars/Sidenav";
import SnackbarComponent from "../components/Alerts/SnackbarComponent";
import { getCurrentUser } from "../components/Helper";

const StudentProfile = () => {
  const profile = JSON.parse(localStorage.getItem('profile'));
  const currentUser = getCurrentUser();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [severity, setSeverity] = useState("success");

  const photoUrl = "https://www.roundpier.com/" + profile?.response?.user?.icon || "/img/default/icon/student3.png"; // Default icon

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMsg("");
    setSeverity("success");
  };

  const student = profile?.response?.user?.student;
  const user = profile?.response?.user;
  console.log(profile?.response?.user?.meta?.og_url)

  return (
    <>
      <Box sx={{ display: "flex", height: "100%" }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, height: "100%" }}>
          {snackbarOpen && (
            <SnackbarComponent
              snackbarOpen={snackbarOpen}
              snackbarMsg={snackbarMsg}
              severity={severity}
              handleSnackbarClose={handleSnackbarClose}
            />
          )}
          <Paper sx={{ p: 4, display: "flex", justifyContent: "center" }}>
            <Grid container direction="column" alignItems="center">
              <Avatar src={photoUrl} sx={{ width: 150, height: 150 }} />
              <Typography variant="h5" color="#333" sx={{ mt: 2 }}>
                {profile?.response?.user?.name}
              </Typography>
              <Typography variant="subtitle1" color="#9e9ea7">
                {student?.currentschool?.name}
              </Typography>
              <Typography variant="body1" color="#666" sx={{ mt: 2 }}>
                Born: {new Date(student?.born).toLocaleDateString()} 
              </Typography>
              <Typography variant="body1" color="#666">
                Progress: {student?.progress}%
              </Typography>
              <Typography variant="h6" color="#333" sx={{ mt: 2 }}>
                Interests
              </Typography>
              {user?.list_interest?.map((interest) => (
                <Typography key={interest.id} variant="body2" color="#666">
                  {interest.name}
                </Typography>
              ))}
              <Typography variant="h6" color="#333" sx={{ mt: 2 }}>
                Location
              </Typography>
              <Typography variant="body2" color="#666">
                {student?.currentschool?.address?.name}, {profile?.response?.user?.location?.city?.name}, {profile?.response?.user?.location?.country?.name}
              </Typography>
            </Grid>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default StudentProfile;
