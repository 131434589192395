import React, { useEffect, useState, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Sidenav from "../components/NavBars/Sidenav";
import ResponsiveAppBar from "../components/NavBars/ResNav";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  collection,
  doc,
  getDoc,
  setDoc,
  Timestamp,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase";
import { AuthContext } from "../components/Auth/Auth";
import ClubHeader from "../../src/components/memberClubDetails/ClubHeader";
import ClubTabs from "../../src/components/memberClubDetails/ClubTabs";
import {
  checkMembership,
  fetchClubById,
  fetchClubs,
} from "../services/apiService";
import axios from "axios";
import { getToken } from "../components/Helper";

export default function MemberClubDetails() {
  const { clubId } = useParams();
  const profile = localStorage.getItem("profile")
    ? JSON.parse(localStorage.getItem("profile"))?.response?.user
    : "";

  const [currentUser, setCurrentUser] = useState(profile);
  const [clubData, setClubData] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const clubs = await fetchClubById(clubId);
      setClubData(clubs);
    }

    const checkMembership = async () => {
      if (currentUser) {
        const membershipsCollection = collection(db, "memberships");
        const membershipsQuery = query(
          membershipsCollection,
          where("user", "==", currentUser.id),
          where("clubId", "==", clubId)
        );
        const membershipsSnapshot = await getDocs(membershipsQuery);
        setIsMember(!membershipsSnapshot.empty);
      }
    };

    const fetchMembers = async () => {
      const membershipsCollection = collection(db, "memberships");
      const membersQuery = query(
        membershipsCollection,
        where("clubId", "==", clubId)
      );
      const membersSnapshot = await getDocs(membersQuery);
      const memberEmails = membersSnapshot.docs.map((doc) => doc.data().user);

      try {
        const token = getToken();
        console.log("token ", token);
        
        const responses = await Promise.all(
          memberEmails.map((id) =>
            axios.get(
              `https://www.roundpier.com/api/user/view/${id}?token=${token}`
            )
          )
        );
        const mappedData = responses.map((response) => {
          let userData = response.data.response.user;
          return {
            name: userData.name,
            type: userData.type,
            icon: userData.meta.og_image,
            otherData: userData,
          };
        });
        setMembers(mappedData);
      } catch (err) {
        console.error(err);
      } finally {
      }
    };

    fetchData();
    checkMembership();
    fetchMembers();
  }, [currentUser, clubId]);

  const handleJoinClub = async () => {
    if (currentUser && clubData) {
      const membershipRef = doc(
        db,
        "memberships",
        `${currentUser.id}_${clubId}`
      );
      await setDoc(membershipRef, {
        user: currentUser.id,
        clubId: clubId,
        dateJoined: Timestamp.fromDate(new Date()),
      });

      try {
        const token = getToken();
        console.log("token ", token);

        
        const responses = await axios.get(
          `https://www.roundpier.com/api/user/view/${currentUser.id}?token=${token}`
        );

        let userData = responses.data.response.user;
        let mappedData = {
          name: userData.name,
          type: userData.type,
          icon: userData.meta.og_image,
          otherData: userData,
        };
        setIsMember(true);
        setMembers((prevMembers) => [...prevMembers, mappedData]);
      } catch (err) {
        console.error(err);
      } finally {
      }
    }
  };

  if (!clubData) return <Typography>Loading...</Typography>;

  return (
    <Box sx={{ py: 6, px: { xs: 2, md: 12, lg: 18, xl: 32 } }}>
      <Paper sx={{ padding: 3 }} elevation={0}>
        <ClubHeader
          clubData={clubData}
          currentUser={currentUser}
          isMember={isMember}
          handleJoinClub={handleJoinClub}
        />

        <ClubTabs
          members={members}
          clubId={clubId}
          clubCategory={clubData?.category}
        />
      </Paper>
    </Box>
  );
}
