import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  MenuItem,
  Button,
  Container,
  Typography,
  Paper,
  Grid,
  Box,
  InputAdornment,
  CircularProgress,
  Alert,
  Snackbar,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import { API_CONFIG } from "../apiConfig.js";
import { useNavigate } from "react-router-dom";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase.js";
import { getCurrentUser } from "../components/Helper.js";
import categories from "../data/categories.json";

const CLUB_IMAGE_URL =
  "https://img.freepik.com/premium-vector/vector-graphic-group-connection-logo-people-circle-logo-team-work_29120-261.jpg";

const teachers = [
  { name: "Ms. Smith" },
  { name: "Mr. Johnson" },
  { name: "Mrs. Doe" },
];

const ClubForm = () => {
  const navigate = useNavigate();
  const clubValuesRef = useRef({
    name: "",
    category: "",
    url: "",
    school: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    teacherName: "",
    email: "",
    admin: "",
  });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState({});
  const currentUser = getCurrentUser();
  const [loading, setLoading] = useState(false);
  const [loadingSates, setLoadingStates] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  
  useEffect(() => {
    axios
      .get(API_CONFIG.COUNTRIES_API)
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    clubValuesRef.current.country = selectedCountry;
    setStates([]);
    setCities([]);
    setErrors((prev) => ({ ...prev, country: "" }));
    setLoadingStates(true);
    axios
      .post(API_CONFIG.STATES_API, { country: selectedCountry })
      .then((response) => {
        setStates(response.data.data.states);
        setLoadingStates(false);
        setErrors((prev) => ({
          ...prev,
          states: response.data.data.states?.length
            ? ""
            : "No corresponding states",
        }));
      })
      .catch((error) => {
        console.error("Error fetching states:", error);
        setLoadingStates(false);
        setErrors((prev) => ({ ...prev, state: "Error fetching states" }));
      });
  };

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    clubValuesRef.current.state = selectedState;
    setCities([]);
    setErrors((prev) => ({ ...prev, state: "" }));
    setLoadingCities(true);
    axios
      .post(API_CONFIG.CITIES_API, {
        country: clubValuesRef.current.country,
        state: selectedState,
      })
      .then((response) => {
        setCities(response.data.data);
        setLoadingCities(false);
        setErrors((prev) => ({
          ...prev,
          cities: response.data.data?.length ? "" : "No corresponding cities",
        }));
      })
      .catch((error) => {
        console.error("Error fetching cities:", error);
        setLoadingCities(false);
        setErrors((prev) => ({ ...prev, cities: "Error fetching cities" }));
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    clubValuesRef.current[name] = value;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;    
    clubValuesRef.current[name] = checked;
    if (checked) {
      clubValuesRef.current["school"] = currentUser?.school || currentUser?.student?.currentschool?.name || "";
    } else {
      clubValuesRef.current["school"] = "";
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!clubValuesRef.current.name) newErrors.name = "Club Name is required";
    if (!clubValuesRef.current.category)
      newErrors.category = "Category is required";
    if (!clubValuesRef.current.school) newErrors.school = "School is required";
    // if (!clubValuesRef.current.country) newErrors.country = "Country is required";
    // if (!clubValuesRef.current.state) newErrors.state = "State is required";
    // if (!clubValuesRef.current.city) newErrors.city = "City is required";
    // if (!clubValuesRef.current.zipCode) newErrors.zipCode = "Zip Code is required";
    if (!clubValuesRef.current.teacherName)
      newErrors.teacherName = "Teacher Name is required";

    if (!clubValuesRef.current.email) {
      newErrors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
        clubValuesRef.current.email
      )
    ) {
      newErrors.email = "Invalid email format";
    }

    if (
      clubValuesRef.current.url &&
      !/^https?:\/\/.*\.(jpeg|jpg|png|gif)$/i.test(clubValuesRef.current.url)
    ) {
      newErrors.url =
        "Invalid URL format for Club Image (must end in .jpg, .jpeg, .png, or .gif)";
    }

    return newErrors;
  };

  const handleCreateClub = async () => {
    setLoading(true);
    try {
      const docRef = await addDoc(collection(db, "clubs"), {
        ...clubValuesRef.current,
        admin: currentUser.id,
        url: CLUB_IMAGE_URL,
      });
      await setDoc(doc(db, "memberships", `${currentUser.id}_${docRef.id}`), {
        user: currentUser.id,
        clubId: docRef.id,
        role: "admin",
        dateJoined: new Date(),
      });
      setLoading(false);
      navigate("/clubs");
    } catch (error) {
      setLoading(false);
      setSnackbarOpen(true);
      console.error("Error adding club:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      handleCreateClub();
    }
  };

  return (
    <Container sx={{ py: 6 }} maxWidth="md">
      <Typography variant="h5" gutterBottom sx={{ pb: 6 }} fontWeight={600}>
        Create a New Club
      </Typography>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} sx={{ width: "100%" }}>
          Some error occurred!
        </Alert>
      </Snackbar>
      <Paper sx={{ p: 6 }}>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Club Name"
                name="name"
                defaultValue={clubValuesRef.current.name}
                onChange={handleChange}
                margin="normal"
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label="Category"
                name="category"
                defaultValue={clubValuesRef.current.category}
                onChange={handleChange}
                margin="normal"
                error={!!errors.category}
                helperText={errors.category}
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.name}>
                    {category.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="School"
                name="school"
                defaultValue={clubValuesRef.current.school}
                onChange={handleChange}
                margin="normal"
                error={!!errors.school}
                helperText={errors.school}
              />
            </Grid> */}

            {/* Country Dropdown */}
            {/* <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label="Country"
                name="country"
                value={clubValuesRef.current.country || ""}
                onChange={handleCountryChange}
                margin="normal"
                error={!!errors.country}
                helperText={errors.country}
              >
                {countries.map((country) => (
                  <MenuItem key={country.country} value={country.country}>
                    {country.country}
                  </MenuItem>
                ))}
              </TextField>
            </Grid> */}
            {/* State Dropdown */}
            {/* <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label="State"
                name="state"
                value={clubValuesRef.current.state || ""}
                onChange={handleStateChange}
                margin="normal"
                error={!!errors.state}
                helperText={errors.state}
                disabled={!states.length}
                InputProps={{
                  startAdornment: loadingSates && (
                    <InputAdornment position="end">
                      <CircularProgress size={20} />
                    </InputAdornment>
                  ),
                }}
              >
                {states.map((state, index) => (
                  <MenuItem key={index} value={state.name}>
                    {state.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid> */}

            {/* City Dropdown */}
            {/* <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label="City"
                name="city"
                value={clubValuesRef.current.city || ""}
                onChange={handleChange}
                margin="normal"
                error={!!errors.city}
                helperText={errors.city}
                disabled={!cities.length}
                InputProps={{
                  startAdornment: loadingCities && (
                    <InputAdornment position="end">
                      <CircularProgress size={20} />
                    </InputAdornment>
                  ),
                }}
              >
                {cities.map((city, index) => (
                  <MenuItem key={index} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </TextField>
            </Grid> */}

            {/* <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Zip Code"
                name="zipCode"
                defaultValue={clubValuesRef.current.zipCode}
                onChange={handleChange}
                margin="normal"
                error={!!errors.zipCode}
                helperText={errors.zipCode}
              />
            </Grid> */}
            {/* <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label="Teacher/Supervisor Name"
                name="teacherName"
                defaultValue={clubValuesRef.current.teacherName}
                onChange={handleChange}
                margin="normal"
                error={!!errors.teacherName}
                helperText={errors.teacherName}
              >
                {teachers.map((teacher, index) => (
                  <MenuItem key={index} value={teacher.name}>
                    {teacher.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid> */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Teacher/Supervisor Name"
                name="teacherName"
                type="teacherName"
                defaultValue={clubValuesRef.current.teacherName}
                onChange={handleChange}
                margin="normal"
                error={!!errors.teacherName}
                helperText={errors.teacherName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Teacher/Supervisor Email"
                name="email"
                type="email"
                defaultValue={clubValuesRef.current.email}
                onChange={handleChange}
                margin="normal"
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                name="linkToSchool"
                defaultChecked={clubValuesRef.current.linkToSchool}
                control={
                  <Checkbox
                    // defaultChecked={clubValuesRef.current.linkToSchool}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                }
                label={<Typography>Is it a School Club?</Typography>}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                fullWidth
                label="Club Image URL"
                name="url"
                defaultValue={clubValuesRef.current.url}
                onChange={handleChange}
                margin="normal"
                error={!!errors.url}
                helperText={errors.url}
              />
            </Grid> */}
          </Grid>
          <Box
            textAlign="center"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
          >
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              style={{ marginTop: "16px", mx: "auto", width: 200 }}
              onClick={() => navigate("/clubs")}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: "16px", mx: "auto", width: 200 }}
              disabled={loading}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default ClubForm;
