import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';

import { Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { fetchClubProjects, fetchProjectsByCategory } from '../services/apiService';

export default function Topics() {
  const { category } = useParams()
  const [clubProjects, setClubProjects] = useState([])
  const [loading, setLoading] = useState([])
  const profile = JSON.parse(localStorage.getItem('profile'));
  const currentUser = profile.response.user;

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      try {
        const clubProjects = await fetchProjectsByCategory(category)
        setClubProjects(clubProjects);
      } catch (error) {
        console.error("Error fetching clubs or memberships:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentUser?.id]);

  return (

    <Box sx={{ py: 6, px: { xs: 2, md: 12, lg: 18, xl: 32 } }}>
      <Typography variant='h5' mb={6} fontWeight={600}>
        Explore RoundPier Topics
      </Typography>
      {clubProjects?.length === 0 && <Typography>No topics available in this club</Typography>}
      <Grid container rowSpacing={{ xs: 2, md: 4, xl: 5 }} columnSpacing={{ xs: 1, sm: 2, md: 4, xl: 5 }}>
        {clubProjects?.length > 0 && clubProjects?.map((topic, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={4} xl={4}>
            <Card sx={{ width: '100%', height: 340 }}>
              <CardMedia
                component="img"
                height="140"
                image={topic.image}
                alt={topic.name}
              />
              <CardContent sx={{ height: 'calc(100% - 140px)', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" color="text.secondary" textAlign='center' gutterBottom>
                  {topic.title}
                </Typography>
                <Typography variant="body1" color="text.secondary" gutterBottom>
                  {topic.description}
                </Typography>
                <Box textAlign='center' mt='auto'>
                  <Button
                    component={RouterLink}
                    to={`/project/${topic.id}?role=new`}
                    variant="contained"
                  >
                    Participate Now
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>


  );
}
