import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Confirmation from '../Alerts/Confirmation';
import { Link, useNavigate } from "react-router-dom";
import logo from '../../assets/svg/logo.svg';
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import Groups2Icon from '@mui/icons-material/Groups2';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';


const drawerWidth = 240;


export default function Navigation() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const removeCookie = () => {
    const baseCookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

    // Remove cookie for the current domain
    document.cookie = baseCookie;

    // Remove cookie for the root domain
    document.cookie = `${baseCookie} domain=.roundpier.com;`;
  };

  const handleLogout = () => {
    // Clear specific items from localStorage
    localStorage.removeItem('profile');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    removeCookie("token");
    navigate("/");
  };

  const DrawerList = (<>
    <Toolbar >
      <Link to="/clubs" style={{ textDecoration: 'none' }}>
        <Box
          sx={{
            width: '140px',
            height: 'auto',
          }}
        >
          <img src={logo} width={"100%"} height={"30px"} alt="RoundPier" />
        </Box>
      </Link>
      {isSmallScreen && <MenuOpenIcon color="secondary" size='large' onClick={toggleDrawer} sx={{ display: 'inline', ml: 'auto' }}>
        <MenuIcon />
      </MenuOpenIcon>}
    </Toolbar>
    <List
      sx={{
        height: "100vh",
        // backgroundColor: "#123860",
        //  color: "#fff" 
      }}
    >
      <ListItem disablePadding onClick={() => navigate("/clubs")}>
        <ListItemButton>
          <Groups2Icon sx={{ minWidth: 0, mr: 3 }} />
          <ListItemText primary="Clubs" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton onClick={() => (window.location.href = "https://www.roundpier.com/competitions")}>
          <GroupWorkIcon sx={{ minWidth: 0, mr: 3 }} />
          <ListItemText primary="Competitions" />
        </ListItemButton>
      </ListItem>
      <Divider />
      <ListItem disablePadding onClick={() => navigate("/account")}>
        <ListItemButton>
          <PersonIcon sx={{ minWidth: 0, mr: 3 }} />
          <ListItemText primary="Account" />
        </ListItemButton>
      </ListItem>
      
      <ListItem disablePadding onClick={() => setOpenConfirm(true)}>
        <ListItemButton>
          <LogoutIcon sx={{ minWidth: 0, mr: 3 }} />
          <ListItemText primary="Log Out" />
        </ListItemButton>
      </ListItem>
    </List>
  </>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Confirmation
        openConfirm={openConfirm}
        setOpenConfirm={setOpenConfirm}
        confirmAction={handleLogout}
        message={"Are you sure you want to logout?"}
      />
      {isSmallScreen && (
        <IconButton color="secondary" onClick={toggleDrawer} sx={{ position: 'fixed', top: 16, left: 16, boxShadow: 3, zIndex: 20, background: '#fff', opacity: '.5' }}>
          <MenuIcon />
        </IconButton>
      )}
      <Drawer
        onClose={toggleDrawer}
        variant={isSmallScreen ? 'temporary' : 'permanent'}
        open={isSmallScreen ? isDrawerOpen : true}
        sx={{
          // display: { xs: 'none', md: 'block' },
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        anchor="left"
      >
        {DrawerList}
      </Drawer>
      {/* <Box sx={{position:'absolute',top:10,left:10}}>
      <IconButton  sx={{display: { xs: 'block', md: 'none' }}} onClick={toggleDrawer(true)} aria-label="delete" size="large">
        <MenuIcon fontSize="inherit" />
      </IconButton>
      </Box> */}

      {/* <Drawer sx={{
        display: { xs: 'block', md: 'none' },
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
         open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer> */}
    </Box>
  );
}
