import React, { useState, useContext, useEffect, useRef } from "react";
import { Box, Button, MenuItem, Typography } from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@emotion/react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import axios from "axios";
import { getToken } from "../Helper";

function createData(memberId, name, school, country, email, inviteAccepted) {
  return {
    memberId,
    name,
    school,
    country,
    email,
    inviteAccepted,
  };
}

// const members = [
//   createData(
//     1,
//     "Alice Johnson",
//     "Greenwood High School",
//     "USA",
//     "alice.johnson@example.com",
//     true
//   ),
//   createData(
//     2,
//     "Bob Smith",
//     "Blue Valley High",
//     "Canada",
//     "bob.smith@example.com",
//     false
//   ),
//   createData(
//     3,
//     "Carlos Garcia",
//     "Saint Mary School",
//     "Mexico",
//     "carlos.garcia@example.com",
//     true
//   ),
//   createData(
//     4,
//     "David Lee",
//     "Sunshine School",
//     "Australia",
//     "david.lee@example.com",
//     false
//   ),
//   createData(
//     5,
//     "Eva Zhang",
//     "Happy Valley School",
//     "China",
//     "eva.zhang@example.com",
//     true
//   ),
//   createData(
//     6,
//     "Fatima Khan",
//     "Springfield School",
//     "UAE",
//     "fatima.khan@example.com",
//     false
//   ),
//   createData(
//     7,
//     "George Brown",
//     "Oakwood High",
//     "UK",
//     "george.brown@example.com",
//     true
//   ),
//   createData(
//     8,
//     "Hannah Martin",
//     "Elm Street School",
//     "Germany",
//     "hannah.martin@example.com",
//     false
//   ),
//   createData(
//     9,
//     "Isabella Rossi",
//     "Florence Academy",
//     "Italy",
//     "isabella.rossi@example.com",
//     true
//   ),
//   createData(
//     10,
//     "Jack Wilson",
//     "Pine Grove High",
//     "New Zealand",
//     "jack.wilson@example.com",
//     false
//   ),
//   createData(
//     11,
//     "Kaito Tanaka",
//     "Tokyo International",
//     "Japan",
//     "kaito.tanaka@example.com",
//     true
//   ),
//   createData(
//     12,
//     "Liam Murphy",
//     "Emerald Secondary",
//     "Ireland",
//     "liam.murphy@example.com",
//     true
//   ),
//   createData(
//     13,
//     "Maria Lopez",
//     "Valley View School",
//     "Spain",
//     "maria.lopez@example.com",
//     false
//   ),
//   createData(
//     14,
//     "Nina Petrov",
//     "Riverbank School",
//     "Russia",
//     "nina.petrov@example.com",
//     true
//   ),
//   createData(
//     15,
//     "Oliver Evans",
//     "Westwood School",
//     "South Africa",
//     "oliver.evans@example.com",
//     false
//   ),
// ];

function getStyles(id, selectedMemberIds, theme) {
  return {
    fontWeight: selectedMemberIds.includes(id)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

export default function ExistingClubProject({
  projectType,
  handleCreateTeam,
  getExistingTeamNames,
}) {
  const profile = JSON.parse(localStorage.getItem("profile"));
  const currentUser = profile.response.user;

  const { clubId } = useParams();
  const [teamDetailsDialog, setTeamDetailsDialog] = useState(false);
  const [members, setMembers] = useState([]);
  const [errors, setErrors] = useState({});
  const theme = useTheme();
  const teamValuesRef = useRef({
    name: "",
    members: [],
  });

  useEffect(() => {
    const fetchMembers = async () => {
      const membershipsCollection = collection(db, "memberships");
      const membersQuery = query(
        membershipsCollection,
        where("clubId", "==", clubId)
      );
      const membersSnapshot = await getDocs(membersQuery);
      const memberEmail = membersSnapshot.docs.map((doc) => doc.data().user);
      const memberEmails = memberEmail.filter(
        (member) => member !== currentUser?.id
      );

      try {
        const token = getToken();

        const responses = await Promise.all(
          memberEmails.map((id) =>
            axios.get(
              `https://www.roundpier.com/api/user/view/${id}?token=${token}`
            )
          )
        );
        const mappedData = responses.map((response) => {
          let userData = response.data.response.user;
          return {
            name: userData.name,
            type: userData.type,
            icon: userData.meta.og_image,
            otherData: userData,
          };
        });
        setMembers(mappedData);
      } catch (err) {
        console.error(err);
      } finally {
      }
    };

    fetchMembers();
  }, [clubId]);

  const handleClickOpen = () => {
    setTeamDetailsDialog(true);
    teamValuesRef.current.name = "";
    teamValuesRef.current.members = [];
  };

  const handleClose = () => {
    setTeamDetailsDialog(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    teamValuesRef.current[name] = value;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleMemberSelection = (event) => {
    const { value } = event.target;
    teamValuesRef.current.members = value;
    setErrors((prev) => ({ ...prev, members: "" }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!teamValuesRef.current.name) newErrors.name = "Team Name is required";
    if (getExistingTeamNames()?.includes(teamValuesRef.current.name))
      newErrors.name = "Team Name already exists";
    // if (
    //   projectType !== "Individual" &&
    //   teamValuesRef.current.members?.length === 0
    // )
    //   newErrors.members = "Team Members are required";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      handleCreateTeam(teamValuesRef.current);
      setTeamDetailsDialog(false);
    }
  };

  return (
    <Box>
      <Box textAlign="center">
        {projectType === "Individual" ? (
          <Button variant="contained" onClick={handleClickOpen}>
            Participate
          </Button>
        ) : (
          <Button variant="contained" onClick={handleClickOpen}>
            Create Team and Participate
          </Button>
        )}
      </Box>

      <Dialog
        disableEscapeKeyDown
        maxWidth="sm"
        open={teamDetailsDialog}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: handleSubmit,
        }}
      >
        <DialogTitle>Create your Team</DialogTitle>
        <DialogContent sx={{ minWidth: "600px" }}>
          <TextField
            fullWidth
            label="Team Name"
            name="name"
            id="name"
            margin="normal"
            defaultValue={teamValuesRef.current.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
          />
          {projectType !== "Individual" && (
            <TextField
              fullWidth
              select
              label="Choose members"
              name="members"
              margin="normal"
              id="members"
              // disabled={members.length === 0}
              error={!!errors.members}
              helperText={
                errors.members ||
                teamValuesRef.current.members.length === 0 ? 
                "You are the only member of the team"
                : `You and  ${teamValuesRef.current.members.length} others are selected`
              }
              SelectProps={{
                multiple: true,
                value: teamValuesRef.current.members,
                onChange: handleMemberSelection,
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                },
              }}
            >
              {members.length > 0 ? (
                members.map((member, index) => (
                  <MenuItem
                    key={member.otherData.id}
                    value={member.otherData.id}
                    style={getStyles(
                      member.memberId,
                      teamValuesRef.current.members,
                      theme
                    )}
                  >
                    {member?.name ? member?.name : member?.otherData.id}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled color="black">No members found</MenuItem>
              )}
            </TextField>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button variant="text" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
