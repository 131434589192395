import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { Button, CardActions, CardMedia, IconButton, Tooltip } from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { fetchEnrollments } from "../../services/apiService";
import { formatTimestamptoDate } from "../Helper";

export default function ProjectList({ projects,enrollments }) {
    const currentDate = (new Date()).toLocaleDateString("en-GB")
    // const [enrollments, setEnrollments] = React.useState([])
    const [modifiedProjectList, setModifiedProjectList] = React.useState([])
    const {clubId} = useParams()
    
    const profile = JSON.parse(localStorage.getItem('profile'));
    const currentUser = profile.response.user;

    useEffect(() => {         
            // setEnrollments(enrollments);
            const projectList = []
            projects.forEach(project=>{
                const isEnrolled = enrollments.some(enrollment=>{
                    return project.id===enrollment.projectId && clubId===enrollment.clubId && enrollment?.teams.some(team=>team?.members?.includes(currentUser?.id))
                })
                projectList.push({...project,isEnrolled:isEnrolled})
            })
            setModifiedProjectList(projectList)
    }, [currentUser?.id,clubId]);

    return <Box sx={{ padding: 2 }}>
        {modifiedProjectList.length > 0 ? (
            <Grid container spacing={2}>
                {modifiedProjectList.map((project, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardMedia
                                sx={{ height: 140 }}
                                image={project.image}
                                title="green iguana"
                            />
                            <CardContent>
                                <Box display='flex'>
                                <Typography gutterBottom variant="h6" >
                                    {project.title}
                                </Typography>
                                <Tooltip title="Share">
                                <IconButton
                                    aria-label="share"
                                    sx={{ml:'auto'}}
                                    >
                                    <ShareIcon />
                                    </IconButton>
                                    </Tooltip>
                                </Box>
                                
                                <Box  sx={{ color: 'text.secondary' }}>
                                    <table>
                                        <tbody>
                                            <tr><td>Start Date&nbsp;</td><td style={{ color: 'text.primary' }}>:&nbsp;&nbsp;{currentDate}</td></tr>
                                            <tr><td>End Date&nbsp;</td><td>:&nbsp;&nbsp;{formatTimestamptoDate(project.endDate)}</td></tr>
                                            <tr><td>Project Type&nbsp;</td><td>:&nbsp;&nbsp;{project.project_type}</td></tr>

                                        </tbody>
                                    </table>
                                    
                                
                                   {/* <p><span style={{ color: 'text.secondary' }}>Start Date: </span>{project.startDate}</p>
                                   <p><span style={{ color: 'text.secondary' }}>End Date: </span>{project.endDate}</p> */}
                                   
                                   
                                </Box>
                                <Box display='flex'>
                                {project?.isEnrolled ? <Button 
                                    component={RouterLink}
                                    to={`/club/${clubId}/project/${project.id}`}
                                    variant="contained" 
                                    sx={{mt:2,mx:'auto'}} >Continue</Button> : 
                                        <Button 
                                    component={RouterLink}
                                    to={`/club/${clubId}/project/${project.id}`}
                                    // onClick={()=>enrollToProject()}
                                    variant="contained" 
                                    sx={{mt:2,mx:'auto'}} >Start</Button>
                                }
                                </Box>
                            </CardContent>
                       
                        </Card>
                    </Grid>
                ))}
            </Grid>
        ) : (
            <Typography>No projects available.</Typography>
        )}
    </Box>
}