import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import { Grid, Card, CardMedia, CardContent } from "@mui/material";
import { Link } from "react-router-dom";

export const ExploreTopics = (props) => {

  return (
    <>
      <Typography variant="h6" marginBottom={3}>
        Explore RoundPier Topics
      </Typography>

      <Grid id="topics-list" container spacing={2}>
        {props.data?.map((topic, index) => (
          <Grid
            item
            key={index}
            xs={12}  // Full width on small screens
            sm={6}   // 2 cards per row on small screens and above
            md={4}   // 3 cards per row on medium screens and above
            lg={4}   // 3 cards per row on large screens
            xl={4}   // 3 cards per row on extra-large screens
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" }, // Center on xs, left on larger screens
            }}
          >
            <Card sx={{ p: 2, width: "100%", height: 200 }}>
              <Link
                style={{ textDecoration: "none" }}
                to={`/club-topics/${topic.category}`}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Avatar
                    src={topic.url}
                    alt={topic.name}
                    sx={{ width: 100, height: 100 }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    textAlign: "center",
                    pt: "16px",
                    height: 88,
                  }}
                >
                  <Typography variant="h6" color="text.secondary">
                    {topic.name}
                  </Typography>
                  {/* Uncomment if you need to display category */}
                  {/* <Typography variant="body" color="text.secondary">
                {topic.category}
              </Typography> */}
                </Box>
              </Link>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>

  );
};
